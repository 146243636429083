<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showpaye"
      max-width="800px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span>
            Montant :
            {{
              numberWithSpace(
                fact ? (fact.mont_ttc ? fact.mont_ttc.toFixed(2) : "0") : "0"
              ) + " DA"
            }}
          </span>
          <v-spacer></v-spacer>
          <span>
            Encaissements :
            {{
              numberWithSpace(
                fact ? (fact.ttc_paye ? fact.ttc_paye.toFixed(2) : "0") : "0"
              ) + " DA"
            }}
          </span>
        </v-card-title>
        <v-card-text>
          <listitems
            :list="paye_list"
            :headers="payeheaders"
            :master="false"
            :add="add"
            :del="false"
            :Update="false"
            :showedit="false"
            @open="add_paye"
          >
          </listitems>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" text @click.stop="closePaye">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import listitems from "./ListItems.vue";
export default {
  components: {
    listitems,
  },
  props: {
    fact: Object,
    paye_list: Array,
    showpaye: Boolean,
    facture: { type: Boolean, default: true },
    add: { type: Boolean, default: false },
  },
  data() {
    return {
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",

      payeheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Paiement No",
          value: "nopaye",
          selected: true,
        },
        {
          text: "Reference",
          value: "paye_ref",
          selected: true,
        },
        {
          text: "Date Paiement",
          value: "paye_date",
          selected: true,
        },

        {
          text: "Montant",
          value: "montant_paye",
          selected: true,
          slot: "cur",
          align: "end",
        },
        {
          text: "Montant Reglé",
          value: "montant",
          selected: true,
          slot: "cur",
          align: "end",
        },
      ],
    };
  },
  methods: {
    add_paye() {
      this.$emit("add");
    },
    closePaye() {
      this.$emit("close");
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>
